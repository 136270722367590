<template>
    <section class="content">
    <div class="container-fluid" v-if="isAdmin">
      
      <div class="row form-horizontal">
        <div class="col-md-4 form-group">
			<label class="control-label">Kurir</label>
            <!-- <div class=""> -->
				<select required v-model="form.kurir" id="kurir" name="kurir" class="form-control">
					<option v-for="courier in totalCourier" v-bind:key="courier.prefix" v-bind:value="courier.prefix">
						{{ courier.label }}
					</option>
				</select>
            <!-- </div> -->
        </div>
        <div class="col-md-4 form-group">
			<!-- <datepicker v-model="date" /> -->
			<label class="control-label">AWB</label>
			<input id="kode" class="form-control" v-model="form.kode" type="text" required="required" name="kode" />
        </div>
        <div class="col-md-4 form-group">
			<input id="status" v-model="form.status" type="checkbox" name="status" />
			<label for="status">RAW Data</label>
        </div>
        <div class="col-auto">
            <button class="btn btn-info" type="button" :disabled="loading" :show="(permission < 3 || permission == 10)" v-on:click="cekTrack">Cek Tracking</button>
            <button class="btn btn-primary ml-2" type="button" :disabled="loading2" :show="(permission < 3 || permission == 10)" v-on:click="updateTrack">Update Tracking</button>
            <router-link
                    :to="{
                      name: 'Update Tracking Manual Batch',
                    }">
                <button class="btn btn-primary ml-2" type="button">Update Tracking Batch</button>
            </router-link>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-12">
            <pre style="max-height:600px;min-height:300px;font-family:'Courier New';overflow: scroll;font-size:14px;" class="border p-1" v-html="textContent"></pre>
        </div>
      </div>
    </div>
    </section>
</template>
<script>
import { createTable, authFetch, auth } from '@/libs/hxcore';
import $ from 'jquery';
import datepicker from '@/components/Datepicker';
import moment from "moment";
import "daterangepicker/daterangepicker.css";
import "icheck-material";

export default {
    name: 'Tracking',
    data() {
        return {
            errors: [],
			totalCourier: [],
            textContent: '',
            loading: false,
            loading2: false,
            isAdmin: false,
            permission:'',
            method: '',
            date:'',
            category: '',
            formTitle: 'Tambah',
			form: {
				kurir: '',
				kode: '',
				status: '',
			},
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;

        var info = auth.user();
        this.permission=info.role_id;
        if (info) {
        this.isAdmin = info.role_id < 11;
        }

		authFetch("/shipper/kurir")
        .then((res) => {
            if (res.status === 201) {} else if (res.status === 400) {}
            return res.json();
        })
        .then((js) => {
            this.totalCourier = js.data;
            $('.loading-overlay').removeClass('show');
        });
    },
    components: {
        datepicker,
    },
	computed: {
        trackRole() {
            const allowRoles = ['1', '2', '3', '6', '10'];
            return allowRoles.includes(this.permission);
        },
	},
    methods: {
		onChange(event) {
			console.log(event);
			this.form.status = event.target.value;
		},
        cekTrack(){
            this.loading = true;
            this.loading2 = true;
			var data = Object.keys(this.form)
			.map((key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
			).join("&");
            authFetch(`/backdoor/tracking?${data}`)
            .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.text();
            })
            .then((text) => {
                this.textContent = JSON.stringify(JSON.parse(text), null, 2);
                this.loading = false;
                this.loading2 = false;
            })
        },
		updateTrack(){
            this.loading = true;
            this.loading2 = true;
			var data = Object.keys(this.form)
			.map((key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
			).join("&");
            authFetch(`/backdoor/tracking/updatenow?${data}`)
            .then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.text();
            })
            .then((text) => {
                this.textContent = JSON.stringify(JSON.parse(text), null, 2);
                this.loading = false;
                this.loading2 = false;
            })
        },
    },
    mounted() {
    }
}
</script>